<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AccountSelector from "@/components/AccountSelector";
import PromocodePreview from "@/components/referral-links/PromocodePreview";
import PromocodeCard from "@/components/referral-links/PromocodeCard.vue";
import NotMetConditionsBlock from "@/components/referral-links/NotMetConditionsBlock.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import MasterAccountRestriction from "@/components/referral-links/MasterAccountRestriction.vue";

export default {
  name: "ReferralLinks",

  components: {
    MasterAccountRestriction,
    MainLoader,
    NotMetConditionsBlock,
    PromocodeCard,
    PromocodePreview,
    AccountSelector,
  },

  data() {
    return {
      isLoading: false,
      restriction: null,
      premiumTariffs: ["vip_d", "vip_gold_d"],
      franchiseTariff: "vip_d",
      referralLinkTariff: ["premium_d", "vip_gold_d"],
      promocodeTariff: ["premium_d", "vip_d", "vip_gold_d"],
      highLevelTariff: ["premium_d", "vip_d", "vip_gold_d"],
      tsp: ["partner", "rcpos"],
      services: [
        {
          id: "client",
          label: "RC PAY",
          link: null,
        },
        {
          id: "distributor",
          label: "RC OFFICE",
          link: null,
        },
        {
          id: "partner",
          label: "RC CITY",
          link: null,
        },
        {
          id: "rcpos",
          label: "RC POS",
          link: null,
        },
      ],
    };
  },

  watch: {
    selectedBinaryAccount: function () {
      this.loadLinks();
    },
  },

  computed: {
    ...mapState({
      referralLinks: (state) => state.referral.referralLinks,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      selectedPromocode: (state) => state.referral.selectedPromocode,
      user: (state) => state.auth.user,
    }),

    ...mapGetters({
      getReferralLinkByPromocode: "referral/getReferralLinkByPromocode",
    }),

    enabledServices() {
      if (
        this.selectedBinaryAccount.position === "master" &&
        this.highLevelTariff.includes(this.selectedBinaryAccount.tariff.code)
      ) {
        if (this.user.latest_exam?.status !== "passed") {
          return this.services.filter(
            (service) => !this.tsp.includes(service.id) && !!service.link
          );
        }

        return this.services.filter((service) => !!service.link);
      }

      if (this.showMasterRestriction) {
        return [];
      }

      return this.services.filter((service) => !!service.link);
    },

    isShowDefaultService() {
      const rcPay = this.enabledServices.find((item) => item.id === "client");
      const rcOffice = this.enabledServices.find(
        (item) => item.id === "distributor"
      );

      return !!rcPay || !!rcOffice;
    },

    /**
     * По схеме компонент показывается если у нас обучение не пройдено
     * И соответственно показываем только на одном тарифе "Франшиза - vip_d"
     *
     * Предыдущее условие было не верно так как отталкивались мы от верхнего
     * бинака с максимальным тарифом
     * @returns {boolean}
     */
    isShowNotMetConditionBlock() {
      const isShowForFranchise =
        this.franchiseTariff === this.selectedBinaryAccount.tariff.code &&
        this.user.latest_exam?.status !== "passed";

      const isShowForLowTariffs =
        !this.highLevelTariff.includes(
          this.selectedBinaryAccount.tariff.code
        ) ||
        (this.selectedBinaryAccount.tariff.code === "premium_d" &&
          this.isShowDefaultService);

      return isShowForFranchise || isShowForLowTariffs;
    },

    notFillLink() {
      return (
        (this.user.tariff.code === "premium_d" &&
          this.selectedBinaryAccount.position === "master") ||
        (this.premiumTariffs.includes(this.user.tariff.code) &&
          this.user.latest_exam?.status !== "passed" &&
          this.selectedBinaryAccount.position === "master")
      );
    },

    useFilterLinks() {
      return (
        (this.user.tariff.code === "premium_d" &&
          this.selectedBinaryAccount.position !== "master") ||
        (this.premiumTariffs.includes(this.user.tariff.code) &&
          this.user.latest_exam?.status !== "passed" &&
          this.selectedBinaryAccount.position !== "master")
      );
    },

    showPromocode() {
      return !!this.enabledServices.length;
    },

    showMasterRestriction() {
      return (
        this.referralLinkTariff.includes(this.user.tariff.code) &&
        this.selectedBinaryAccount.position === "master"
      );
    },

    isVisibleEmptyMessage() {
      return this.showMasterRestriction && !this.enabledServices.length;
    },
  },

  methods: {
    ...mapActions({
      loadReferralLinks: "referral/loadReferralLinks",
    }),

    loadLinks() {
      this.isLoading = true;
      this.loadReferralLinks(this.selectedBinaryAccount.id)
        .then(() => {
          if (this.useFilterLinks) {
            this.services.forEach((service) => {
              if (service.id !== "partner" && service.id !== "rcpos") {
                service.link = this.referralLinks[0].urls[service.id] ?? null;
              } else {
                service.link = null;
              }
            });

            return;
          }

          this.services.forEach((service) => {
            service.link = this.referralLinks[0].urls[service.id] ?? null;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // checkRestrictions(serviceId) {
    //   if (serviceId !== "partner") return false;
    //
    //   if (
    //     this.selectedBinaryAccount.tariff?.code !== "vip_d" &&
    //     this.selectedBinaryAccount.tariff?.code !== "vip_gold_d"
    //   ) {
    //     this.restriction = "tariff.vip_d.upper.required";
    //
    //     return true;
    //   }
    //
    //   if (this.user.latest_exam?.status !== "passed") {
    //     this.restriction = "exam.required";
    //
    //     return true;
    //   }
    //
    //   return false;
    // },
  },

  mounted() {
    this.loadLinks();
  },
};
</script>

<template>
  <div class="referral-links">
    <div class="referral-links__account account">
      <div class="account-selector-wrapper">
        <AccountSelector />
      </div>

      <div class="account__wrapper">
        <div v-if="showPromocode" class="account__promo-codes">
          <PromocodePreview
            v-for="referralLink in referralLinks"
            :key="referralLink.promocode"
            :promocode="referralLink.promocode"
            :referral-link="referralLink"
          />
        </div>

        <h3>
          <img src="../../assets/icons/referral_links.svg" alt="" /> Реферальные
          ссылки
        </h3>

        <MainLoader v-if="isLoading" />

        <section v-else class="referral-links__services-list">
          <MasterAccountRestriction v-if="isVisibleEmptyMessage" />

          <PromocodeCard
            v-for="service in enabledServices"
            :key="service.id"
            :service="service"
          />

          <NotMetConditionsBlock v-if="isShowNotMetConditionBlock" />
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.referral-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 64px;

  &__account {
    width: 100%;
    height: 100%;
  }

  &__services-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.account {
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: $background-white;
    padding: $space-xxl;

    @media (min-width: 426px) and (max-width: 576px) {
      padding: $space-xl;
    }

    @media (max-width: 425px) {
      padding: $space-xl $space-l;
    }

    @media (max-width: 375px) {
      padding: $space-xl $space-m;
    }

    h3 {
      @include text-1;
      color: $dark-primary;
      margin-bottom: $space-xl;
    }
  }

  &__promo-codes {
    margin-bottom: $space-xl;
  }
}

@media (min-width: 1024px) {
  .referral-links {
    &__services-list {
      gap: 24px;
    }
  }
}

@media (min-width: 1440px) {
  .referral-links {
    &__services-list {
      max-width: 940px;
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
