<script>
import MainInput from "@/components/helpers/MainInput.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { mapActions } from "vuex";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import Share from "@/components/Share.vue";

export default {
  name: "PromocodeCard",
  components: { Share, MainButtonIcon, VueQrcode, MainButton, MainInput },

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      link: "",
      isShowPopperShare: false,
    };
  },

  computed: {
    getClassIcon() {
      switch (this.service.label) {
        case "RC PAY":
          return "icon-logo-yellow-rc-pay";

        case "RC OFFICE":
          return "icon-logo-yellow-rc-office";

        case "RC CITY":
          return "icon-logo-yellow-rc-city";

        case "RC POS":
          return "icon-logo-purple-rc-pos";

        default:
          return "";
      }
    },

    emailText() {
      return {
        subject: `Ссылка для регистрации в ${this.service.label}`,
        body: `Привет!%0D%0AОтправляю тебе ссылку для регистрации в ${
          this.service.label
        }.%0D%0A%0D%0A${encodeURIComponent(this.service.link)}`,
      };
    },
  },

  methods: {
    ...mapActions({
      createNotification: "createNotification",
    }),

    copyLink() {
      navigator.clipboard.writeText(this.service.link).then(() => {
        this.createNotification({
          title: "Ссылка скопирована",
          type: "success",
        });
      });
    },

    share() {
      if (navigator.share) {
        navigator.share({
          title: `Ссылка для регистрации в ${this.service.label}`,
          text: `Привет! Отправляю тебе ссылку для регистрации в ${this.service.label}.`,
          url: this.service.link,
        });

        return;
      }

      this.isShowPopperShare = true;
    },
  },

  mounted() {
    this.link = this.service.link;
  },
};
</script>

<template>
  <div class="promocode-card">
    <div class="promocode-card__qr-wrapper">
      <VueQrcode :value="service.link" :options="{ width: 180, margin: 0 }" />
    </div>

    <h3>
      <span
        v-if="getClassIcon"
        :class="['promocode-card__logo', getClassIcon]"
      />
      {{ service.label }}
    </h3>

    <MainInput v-model="link" readonly background="white">
      <template #icon>
        <MainButtonIcon @click="copyLink">
          <span class="icon-copy-new" />
        </MainButtonIcon>
      </template>
    </MainInput>

    <VDropdown
      popper-class="promocode-card__dropdown"
      placement="top-start"
      :triggers="[]"
      :distance="12"
      :shown.sync="isShowPopperShare"
    >
      <MainButton title="Поделиться ссылкой" color="dark" @click="share" />

      <template v-if="isShowPopperShare" #popper>
        <div>
          <Share
            :url="service.link"
            :email-text="emailText"
            title-copy="Ссылка скопирована"
            @close="isShowPopperShare = false"
          />
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<style lang="scss" scoped>
.promocode-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: $light-second;

  ::v-deep .v-popper {
    width: 100%;
  }

  &__qr-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 12px;
    background: $light-primary;
    box-shadow: 0 0 25px 0 rgba(33, 33, 33, 0.1);
    margin-bottom: 16px;
  }

  h3 {
    width: 100%;
    margin-bottom: 24px;
    @include title-3;
    text-align: center;
    color: $dark-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-s;
  }

  &__logo {
    width: 40px;
    height: 40px;

    @media (max-width: 1024px) {
      width: 32px;
      height: 32px;
    }
  }

  .main-input {
    width: 100%;
    margin-bottom: 8px;
  }

  .icon-copy-new {
    min-width: 20px;
    height: 20px;
    background: $dark-primary;
  }

  .main-button-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    width: fit-content;
    height: fit-content;
  }
}

@media (min-width: 900px) {
  .promocode-card {
    padding: 32px;

    h3 {
      margin-bottom: 32px;
    }
  }
}
</style>
